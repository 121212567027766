
import Table from '@/components/Table.vue';
import TableHeaderButtons from '@/components/TableHeaderButtons.vue'
import SearchPanel from '@/components/SearchPanel.vue';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import { debounce } from 'lodash';
import { buildSearchFilters, buildJsonServerQuery, clearSearchFilters } from '@/utils/app-util';
import { Component } from 'vue-property-decorator';
import Vue from 'vue';
import { flotaModule } from '@/store/modules/flotas';
import { appModule } from '@/store/modules/app';
import { userModule } from '@/store/modules/user';

@Component({
  components: {
    Table,
    TableHeaderButtons,
    SearchPanel,
    ConfirmDialog
  }
})
export default class FlotaList extends Vue {
  public dialog = false;
  public dialogTitle = '';
  public dialogText = '';
  public showSearchPanel = false;
  public right = true;
  public search = '';
  public headers = [
    { text: 'Nombre', value: 'nombre' },
    { text: 'Vehículos', value: 'n_vehiculos' },
    { text: '', value: 'actions', sortable: false }
  ];
  private searchFilter = {
    contain: {
      reference: '',
      customer: ''
    },
    greaterThanOrEqual:{
      amount: 0
    },
    lessThanOrEqual:{
      amount: 0
    },
  };
  // private orderId = '';
  private query = '';
  private color = '';
  private quickSearchFilter = '';
  private itemId = -1;

  edit(item) {
    this.$router.push(`flota/${item.id}`);
  }
  view(item) {
    flotaModule.getFlotaById(item.id);
    this.$router.push(`flota/${item.id}/bases`);
  }
  add() {
    this.$router.push('NewFlota');
  }
  remove(item) {
    this.itemId = item.id;
    this.dialog = true;
  }
  onConfirm() {
    flotaModule.deleteFlota(this.itemId);
    this.dialog = false;
  }
  onCancel() {
    this.itemId = -1;
    this.dialog = false;
  }

  clearSearchFilters() {
    this.showSearchPanel = !this.showSearchPanel;
    clearSearchFilters(this.searchFilter);
    flotaModule.getAllFlotas();
  }

  reloadData() {
    console.log(this.$t("message.Borrar flota"));
    this.query = '';
    flotaModule.getAllFlotas();
  }

   updateSearchPanel(){
    this.rightDrawer=!this.rightDrawer;
  }

  cancelSearch() {
    this.showSearchPanel = false;
  }

  closeSnackbar() {
    appModule.closeNotice();
  }

  get user() {
    return userModule.user;
  }

  get items() {
    return flotaModule.items;
  }
  get pagination() {
    return flotaModule.pagination;
  }
  get loading() {
    return appModule.loading;
  }
  get mode() {
    return appModule.mode;
  }
  get snackbar() {
    return appModule.snackbar;
  }
  get notice() {
    return appModule.notice;
  }

  get rightDrawer() {
    return this.showSearchPanel;
  }

  set rightDrawer(_showSearchPanel: boolean) {
    this.showSearchPanel = _showSearchPanel;
  }

  created() {
    flotaModule.getAllFlotas();
  }
  mounted() {
    this.dialogTitle = this.$t("message.Borrar flota");
    this.dialogText = this.$t("message.¿Quieres borrar esta flota?");
    this.headers = [
      { text: this.$t("message.Nombre"), value: 'nombre' },
      { text: this.$t("message.Vehículos"), value: 'n_vehiculos' },
      { text: '', value: 'actions', sortable: false }
    ];
    // this.$nextTick(() => {
    //   console.log(this.headers);
    // });
  }
}
