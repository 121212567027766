
import Table from '@/components/Table.vue';
import TableHeaderButtons from '@/components/TableHeaderButtons.vue'
import Vue from 'vue';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import { Component, Watch } from 'vue-property-decorator';
import { appModule } from '@/store/modules/app';
import { getData, putData, postData, deleteData } from "@/utils/backend-api";

@Component({
  components: {
    Table,
    TableHeaderButtons,
    ConfirmDialog,
  }
})
export default class ImportarNeumaticosForm extends Vue {
  private title= 'Importar neumáticos';
  private file= null;
  private uploading= false;
  private uploadSuccess= false;
  private uploadError= '';
  private csvFileUrl= "/assets/importar-neumaticos.csv";

  get loading() {
    return appModule.loading;
  }

  get notice() {
    return appModule.notice;
  }

  get snackbar() {
    return appModule.snackbar;
  }

  get mode() {
    return appModule.mode;
  }

  handleFileChange(event) {
    // Captura el archivo seleccionado por el usuario
    this.file = event.target.files[0];
  }

  async uploadCSV() {
    if (!this.file) {
      this.uploadError = "Por favor, seleccione un archivo CSV.";
      appModule.sendErrorNotice(this.uploadError);
      appModule.closeNoticeWithDelay(1500);
      return;
    }

    this.uploading = true;

    // Crear un formulario para enviar el archivo
    const formData = new FormData();
    formData.append("csvFile", this.file);

    try {
      await postData("tipos_neumatico/", formData)
          .then(res  => {
            console.log(res);
            this.uploadSuccess = true;
            this.uploading = false;
            this.file = null;
            appModule.sendSuccessNotice("Neumáticos importados.");
          })
          .catch((err: TODO) => {
            console.log(err);
            appModule.sendErrorNotice(
              "Operation failed! Please try again later. "
            );
            appModule.closeNoticeWithDelay(1500);
          });
    } catch (error) {
      this.uploadError = "Error al cargar el archivo CSV.";
      this.uploading = false;
      console.error(error);
    }
  }

  getCSV() {
    getData("tipos_neumatico/")
        .then(res  => {
          //console.log(res.data);
          const neumaticos= res.data;
          const csvData = this.convertToCSV(neumaticos);
          const blob = new Blob([csvData], { type: "text/csv" });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "neumaticos.csv");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((err: TODO) => {
          console.log(err);
          appModule.sendErrorNotice(
            "Operation failed! Please try again later. "
          );
          appModule.closeNoticeWithDelay(1500);
        });
  }

  convertToCSV(neumaticos) {
    const headers = "nombre";
    const rows = neumaticos.map(obj => obj["nombre"]);
    return headers + "\n" + rows.join("\n");
  }

  closeSnackbar() {
    appModule.closeNotice();
  }
}
