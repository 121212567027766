
import Table from '@/components/Table.vue';
import TableHeaderButtons from '@/components/TableHeaderButtons.vue'
import Vue from 'vue';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import { Component, Watch } from 'vue-property-decorator';
import { flotaModule } from '@/store/modules/flotas';
import { appModule } from '@/store/modules/app';
import { userModule } from '@/store/modules/user';

@Component({
  components: {
    Table,
    TableHeaderButtons,
    ConfirmDialog
  }
})
export default class UsuarioForm extends Vue {
  private errors = [];
  //private title = '';
  private data: [];
  private dialog = false;
  private user= {usr: '', pwd: ''}
  private title = '';

  get loading() {
    return appModule.loading;
  }

  get mode() {
    return appModule.mode;
  }

  get snackbar() {
    return appModule.snackbar;
  }

  get notice() {
    return appModule.notice;
  }

  get tiposRol() {
      const c= [{ text:'Admin', value:'admin'}, { text:'Técnico', value:'user'}, { text:'Taller', value:'taller'}, { text:'Cliente', value:'cliente'}]
      return c;
  }

  async save() {
    if((this.$refs.form as Vue & { validate: () => boolean }).validate()){
      console.log(this.user);
      await userModule.createUser(this.user);
      this.$router.push(`/usuarios`);
    }
  }

  cancel() {
    this.$router.push(`/usuarios`);
  }

  closeSnackbar() {
    appModule.closeNotice();
  }

  created() {
    //userModule.getUser(this.$route.params.id);
  }

  mounted() {
    if (this.$route.params.id) {
      this.title = 'Editar usuario';
    } else{
      this.title = 'Nuevo usuario';
    }
  }
}
