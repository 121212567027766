
import Table from '@/components/Table.vue';
import TableHeaderButtons from '@/components/TableHeaderButtons.vue'
import Vue from 'vue';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import { Component, Watch } from 'vue-property-decorator';
import { vehiculoModule } from '@/store/modules/vehiculos';
import { neumaticoModule } from '@/store/modules/neumaticos';
import { servicioModule } from '@/store/modules/servicios';
import { flotaModule } from '@/store/modules/flotas';
import { appModule } from '@/store/modules/app';
import ServicioVehiculo from '@/components/ServicioVehiculo.vue'
import axios from "axios";
import VehicleTiresData from "@/components/vehicleTiresData/index.vue";
import { userModule } from '@/store/modules/user';
import { baseModule } from '@/store/modules/bases';

@Component({
  components: {
    Table,
    TableHeaderButtons,
    ConfirmDialog,
    VehicleTiresData,
    ServicioVehiculo
  }
})
export default class VehiculoForm extends Vue {
  private errors = [];
  private title = '';
  private data: [];
  private dialog = false;
  private dialogTitle = "";
  private dialogText = "";
  public headers = [
    { text: 'Nombre', value: 'nombre' },
    { text: 'Fecha', value: 'fecha' },
    { text: '', value: 'actions', sortable: false }
  ];
  private URL: '';

  private modalTitle = 'Nuevo neumático';
  private modalText = '';
  private addNeumaticoModal = false;
  private addServicioNeumaticoModal= false;

  private vehiculoMenus: AppMenu[]  = [
    {
      //icon: 'bubble_chart',
      title: 'Nueva inspección',
      link: 'inspeccion'
    },
    {
      //icon: 'bubble_chart',
      title: 'Nuevo servicio',
      link: 'servicio'
    }
  ];
  private neumaticosVehiculo= [];
  private neumaticoDefecto= {
    agregado: false,
    bar_medido: null,
    bar_recomendado: null,
    bar_corregido: null,
    presion: null,
    mm_prof_3: null,
    mm_prof_2: null,
    mm_prof_1: null,
    mm: null,
    posicion: null,
    id: null,
    n_serie: null,
    id_tipo_neumatico: null,
    id_vehiculo: null,
    reesculturado: null
  };
  private currentAction = "";

  get vehiculo() {
    return vehiculoModule.vehiculo;
  }

  get neumatico() {
    return neumaticoModule.neumatico;
  }

  get servicio() {
    return servicioModule.servicio;
  }

  get servicioNeumatico() {
    return servicioModule.servicioNeumatico;
  }

  get allTiposNeumaticos() {
    return neumaticoModule.tiposNeumatico;
  }

  get allPosicionEje() {
      const tipoVehiculo= vehiculoModule.vehiculo.tipo_vehiculo;
      let posicion= [];
      if(tipoVehiculo == 1){
        posicion= [{ text:'1I', value:"1I"}, { text:'1D', value:"1D"}, { text:'2IE', value:"2IE"}, { text:'2II', value:"2II"}, { text:'2DI', value:"2DI"},{ text:'2DE', value:"2DE"}, { text:'3', value:"3", optional:"true"}, { text:'4', value:"4", optional:"true"}];
      }
      else if(tipoVehiculo == 2){
        posicion= [{ text:'1I', value:"1I"}, { text:'1D', value:"1D"}, { text:'2I', value:"2I"}, { text:'2D', value:"2D"}, { text:'3I', value:"3I"},{ text:'3D', value:"3D"}, { text:'4', value:"4", optional:"true"}, { text:'5', value:"5", optional:"true"}];
      }
      else if(tipoVehiculo == 3){
        posicion= [{ text:'1I', value:"1I"}, { text:'1D', value:"1D"}, { text:'2I', value:"2I"}, { text:'2D', value:"2D"}, { text:'3', value:"3", optional:"true"}, { text:'4', value:"4", optional:"true"}];
      }
      else if(tipoVehiculo == 4){
        posicion= [{ text:'1IE', value:"1IE"}, { text:'1II', value:"1II"}, { text:'1DI', value:"1DI"}, { text:'1DE', value:"1DE"}, { text:'2IE', value:"2IE"}, { text:'2II', value:"2II"}, { text:'2DI', value:"2DI"}, { text:'2DE', value:"2DE"}, { text:'3', value:"3", optional:"true"}, { text:'4', value:"4", optional:"true"}];
      }
      else if(tipoVehiculo == 5){
        posicion= [{ text:'1IE', value:"1IE"}, { text:'1II', value:"1II"}, { text:'1DI', value:"1DI"}, { text:'1DE', value:"1DE"}, { text:'2IE', value:"2IE"}, { text:'2II', value:"2II"}, { text:'2DI', value:"2DI"}, { text:'2DE', value:"2DE"}, { text:'3IE', value:"3IE"}, { text:'3II', value:"3II"}, { text:'3DI', value:"3DI"}, { text:'3DE', value:"3DE"}, { text:'4', value:"4", optional:"true"}, { text:'5', value:"5", optional:"true"}];
      }
      return posicion;
  }

  get allPosicionEjePermutacion() {
    const neumaticosPosicion= this.allPosicionEje.map(tireData => {
      const tireFound = {...this.vehiculo.neumaticos.find(
        value => value.posicion == tireData.value
      )};
      if(tireData && (tireData.value== this.neumatico.posicion || !tireFound.id)){
        tireFound.disabled= true;
      }
      return { ...tireData, ...tireFound };
    });
    return neumaticosPosicion;
  }

  get allBases() {
    return vehiculoModule.bases;
  }

  get tiposVehiculo() {
      const c= [{ text:'(1I/1D) (2IE,2II/2DI,2DE)', value:1}, { text:'(1I/1D) (2I,2D) (3I,3D)', value:2}, { text:'(1I/1D) (2I/2D)', value:3}, { text:'(1IE,1II/1DI,1DE) (2IE,2II/2DI,2DE)', value:4}, { text:'(1IE,1II/1DI,1DE) (2IE,2II/2DI,2DE) (3IE,3II/3DI,3DE)', value:5}, { text:'(1I/1D) (2IE,2II/2DI,2DE) (3IE,3II/3DI,3DE)', value:6}, { text:'(1I/1D) (2IE,2II/2DI,2DE) (3I/3D)', value:7}]
      return c;
  }

  get claseVehiculo() {
      const c= [{ text:this.$t("message.Cabeza tractora"), value:'Cabeza tractora'}, { text:this.$t("message.Remolque"), value:'Remolque'}, { text:this.$t("message.Remolque sin km"), value:'remolque_sin_km'}, { text:this.$t("message.Rígido"), value:'Rigido'}, { text:this.$t("message.Dumper"), value:'Dumper'}, { text:this.$t("message.Pala cargadora"), value:'Pala cargadora'}, { text:this.$t("message.Tractor"), value:'Tractor'}, { text:this.$t("message.Lagarto"), value:'Lagarto'}, { text:this.$t("message.Autobús"), value:'Autobus'}, { text:this.$t("message.Turismo"), value:'Turismo'}]
      return c;
  }

  get motivoCambio() {
      const c= [{ text:this.$t("message.Fin de vida por desgaste"), value:'fin de vida por desgaste'}, { text:this.$t("message.Siniestro"), value:'siniestro'}]
      return c;
  }

  get getTipoVehiculo() {
      console.log(vehiculoModule.vehiculo.tipo_vehiculo)
      const tipoVehiculo= vehiculoModule.vehiculo.tipo_vehiculo;
      let URL= "";
      if(tipoVehiculo == 1){
        URL= "https://coolproyect.es/vue/EJE_1_NUM.png";
      }
      else if(tipoVehiculo == 2){
        URL= "https://coolproyect.es/vue/EJE_2_NUM.png";
      }
      else if(tipoVehiculo == 3){
        URL= "https://coolproyect.es/vue/EJE_3_NUM.png";
      }
      return URL;
  }

  get pagination() {
    return servicioModule.pagination;
  }

  get servicios() {
    //console.log(this.vehiculo.servicios);
    const servicios= this.vehiculo.servicios;
    if(servicios){
      servicios.forEach(servicio => {
      servicio.nombre= this.$t("message."+servicio.nombre);
      });
    }
    return servicios;
  }

  edit(item) {
    this.$router.push(`/servicios_vehiculo/${item.id}`);
  }

  addNeumatico(tireInfo) {
    //console.log(tireInfo);
    //console.log(this.neumatico);
    this.modalTitle= this.$t("message.Nuevo neumático");
    if(tireInfo.id || tireInfo.agregado){
      const neumatico= this.vehiculo.neumaticos.find(
        value => value.posicion == tireInfo.posicion
      );
      neumaticoModule.setNeumatico(neumatico);
    }
    else{
      //(this.$refs.form_neumatico as any).reset();
      //this.neumatico.posicion= tireInfo.posicion;
      neumaticoModule.setNeumatico(tireInfo);
    }
    //console.log(this.neumatico);
    this.addNeumaticoModal = true;
  }

  saveNeumatico() {
    if((this.$refs.form_neumatico as Vue & { validate: () => boolean }).validate()){
      const neumatico = { ...this.neumatico};
      delete neumatico["reesculturado"];
      //console.log(neumatico);
      //console.log(this.vehiculo.neumaticos);
      neumatico.mm= (Number(neumatico.mm_prof_1)+ Number(neumatico.mm_prof_2)+ Number(neumatico.mm_prof_3)) / 3;
      neumatico.presion= neumatico.bar_corregido;
      if(!neumatico.agregado && !neumatico.id){
        if(this.vehiculo.id){
          neumatico.id_vehiculo= this.vehiculo.id;
          neumaticoModule.saveNeumatico(neumatico);
        }
        else{
          neumatico.agregado= true;
          //this.neumaticosVehiculo.push(neumatico);
          //console.log(this.vehiculo);
          this.vehiculo.neumaticos.push(neumatico);
        }
      }
      //this.neumatico= this.neumaticoDefecto;
      //neumaticoModule.setNeumatico(this.neumaticoDefecto);
      //(this.$refs.form_neumatico as any).reset();
      //(this.$refs.form_neumatico as any).resetValidation();
      this.addNeumaticoModal = false;
    }
  }

  cancelAddNeumatico() {
    this.addNeumaticoModal = false;
    //(this.$refs.form_neumatico as any).reset();
    //(this.$refs.form_neumatico as any).resetValidation();
  }

  cancelAddServicioNeumatico() {
    this.addServicioNeumaticoModal = false;
    const servicioNeumaticoReset= {
      id: 0,
      id_tipo_servicio: 0,
      id_neumatico: 0,
      km_vehiculo: 0,
      fecha: "",
      id_proveedor_servicio: 0,
      motivo_cambio: "",
      comentario: "",
      id_permutacion: 0
    }
    servicioModule.setServicioNeumatico(servicioNeumaticoReset);
    (this.$refs.form_servicio_neumaticoas as any).resetValidation();
  }

  addInspeccion() {
    if(this.vehiculo.fecha_ultima_inspeccion){
      this.dialogTitle = this.$t("message.Nueva inspección");
      this.dialogText = this.$t("message.aviso_inspeccion");
      this.dialogText+= this.vehiculo.fecha_ultima_inspeccion+ " "+this.$t("message.¿Deséa realizarla?");
      this.dialog= true;
      this.currentAction= "inspeccion";
    }
    else{
      this.$router.push(`/vehiculo/${this.vehiculo.id}/newinspeccion`);
    }
  }

  addServicioNeumatico() {
    this.addNeumaticoModal = false;
    this.addServicioNeumaticoModal= true;
    this.modalTitle= this.$t("message.Añadir servicio neumático");
  }

  addServicio() {
    appModule.setModal(true);
    this.modalTitle= "Añadir servicio vehículo";
  }

  saveServicioNeumatico() {
    if((this.$refs.form_servicio_neumatico as Vue & { validate: () => boolean }).validate()){
      const servicioNeumatico = { ...this.servicioNeumatico };
      servicioNeumatico.id_neumatico= this.neumatico.id;
      servicioNeumatico.id_proveedor_servicio= userModule.user.id;
      servicioNeumatico.id_tipo_servicio= this.servicioNeumatico.id_tipo_servicio;
      /*const fecha= new Date().toISOString();
      console.log(fecha);
      servicioNeumatico.fecha= fecha.split('T')[0]+" "+ fecha.split('T')[1].substring(0, 8);*/
      const fecha= new Date();
      fecha.setHours(fecha.getHours() + 2);
      servicioNeumatico.fecha= fecha.toISOString().split('T')[0]+" "+ fecha.toISOString().split('T')[1].substring(0, 8);
      //console.log(servicioNeumatico);
      if(servicioNeumatico.id_tipo_servicio== 1){
        const posicion= this.neumatico.posicion;
        const neumatico = {... this.neumatico};
        neumatico.posicion= posicion;
        neumatico.id= null;
        neumatico.id_tipo_neumatico= 0;
        neumatico.id_vehiculo= this.neumatico.id_vehiculo;
        neumatico.mm= 0;
        neumatico.reesculturado= 0;
        neumatico.presion= 0;
        neumatico.n_serie= '';
        neumaticoModule.setNeumatico(neumatico);
        (this.$refs.form_neumatico as any).resetValidation();
        //console.log(neumatico);
        this.addNeumatico(neumatico);
      }
      else if(servicioNeumatico.id_tipo_servicio== 4){
        const id_permutacion= servicioNeumatico.id_permutacion;
        const posicion= this.neumatico.posicion;
        const neumatico_permutacion = this.vehiculo.neumaticos.find(
          value => value.id == id_permutacion
        );
        const posicion_permutacion= neumatico_permutacion.posicion;
        neumatico_permutacion.posicion= posicion;
        this.neumatico.posicion= posicion_permutacion;
        delete this.neumatico["reesculturado"];
        delete neumatico_permutacion["reesculturado"];
        neumaticoModule.saveNeumatico(this.neumatico);
        neumaticoModule.saveNeumatico(neumatico_permutacion);
        delete servicioNeumatico["id_permutacion"];
        const servicioNeumaticoPermutacion= { ...servicioNeumatico };
        servicioNeumaticoPermutacion.id_neumatico= id_permutacion;
        servicioModule.saveServicioNeumatico(servicioNeumaticoPermutacion);
      }
      else if(servicioNeumatico.id_tipo_servicio== 7){
        servicioNeumatico.mm= (Number(servicioNeumatico.mm_prof_1)+ Number(servicioNeumatico.mm_prof_2)+ Number(servicioNeumatico.mm_prof_3)) / 3;
      }
      delete servicioNeumatico["id_permutacion"];
      servicioModule.saveServicioNeumatico(servicioNeumatico);
      const servicioNeumaticoReset= {... this.servicioNeumatico};
      servicioNeumaticoReset.id_tipo_servicio= 0;
      servicioNeumaticoReset.id_neumatico= 0;
      servicioNeumaticoReset.km_vehiculo= 0;
      servicioNeumaticoReset.fecha= "";
      servicioNeumaticoReset.id_proveedor_servicio= 0;
      servicioNeumaticoReset.id_tipo_servicio= 0;
      servicioNeumaticoReset.motivo_cambio= "";
      servicioNeumaticoReset.comentario= "";
      servicioNeumaticoReset.id_permutacion= 0;
      servicioNeumaticoReset.mm= "";
      servicioNeumaticoReset.mm_prof_1= "";
      servicioNeumaticoReset.mm_prof_2= "";
      servicioNeumaticoReset.mm_prof_3= "";
      servicioModule.setServicioNeumatico(servicioNeumaticoReset);
      console.log(servicioModule.servicioNeumatico);
      (this.$refs.form_servicio_neumatico as any).resetValidation();
      this.addServicioNeumaticoModal = false;

    }
  }

  get loading() {
    return appModule.loading;
  }

  get mode() {
    return appModule.mode;
  }

  get snackbar() {
    return appModule.snackbar;
  }

  get notice() {
    return appModule.notice;
  }

  get tiposServicioNeumatico() {
    const tiposServicio= [{ text:this.$t("message.Desmontar neumático"), value:'1'}, { text:this.$t("message.Permutación"), value:'4'}, { text:this.$t("message.Equilibrado de neumático"), value:'5'}, { text:this.$t("message.Equilibrado polvos-sacos"), value:'6'}, { text:this.$t("message.Reesculturado"), value:'7'} , { text:this.$t("message.Reparación pinchazo"), value:'12'}, { text:this.$t("message.Rotación en llanta"), value:'14'}];
    return tiposServicio;
  }

  nuevoServioInspeccion(item) {
    if(item.link== "inspeccion"){
      this.addInspeccion();
    }
    else{
      this.addServicio();
    }
  }

  customFilter (item, queryText, itemText) {
    const wordsToSearch = queryText.toLowerCase().split(' ');
    const textToSearch = item.nombre.toLowerCase();
    return wordsToSearch.every(word => textToSearch.includes(word));
  }

  save() {
    if((this.$refs.form as Vue & { validate: () => boolean }).validate()){
      //console.log(this.checkNeumaticosVehiculo(vehiculo.neumaticos));
      this.saveVehiculo();
      //console.log(`/base/${vehiculo.id}/vehiculos`);
      //this.$router.push(`/vehiculo/${vehiculo.id}`);
    }
  }

  saveVehiculo() {
    const vehiculo = { ...this.vehiculo };
    delete vehiculo["servicios"];
    delete vehiculo["fecha_proxima_inspeccion"];
    //delete vehiculo["neumaticos"];
    delete vehiculo["fecha_ultima_inspeccion"];
    if(!vehiculo.km){
      vehiculo.km= 0;
    }
    //console.log(vehiculo);
    if(this.checkNeumaticosVehiculo(vehiculo.neumaticos) && !this.vehiculo.id){
      this.currentAction= "checkNeumaticos";
      this.dialog = true;
    }
    else{
      vehiculoModule.saveVehiculo(vehiculo);
    }
  }

  checkNeumaticosVehiculo(neumaticos) {
    //console.log(neumaticos);
    let error= false;
    const neumaticosPosicion= this.allPosicionEje.map(tireData => {
      const tireFound = neumaticos.find(
        value => value.posicion == tireData.value && !tireData.optional
      );
      return { ...tireData, ...tireFound };
    });

    neumaticosPosicion.map(tireData => {
      if(!tireData.agregado && !tireData.optional){
        error= true;
      }
    });
    return error;

  }

  async getVehiculoById() {
    await vehiculoModule.getVehiculoById(parseInt(this.$route.params.id));
  }

  getNeumaticosByVehiculoId() {
    vehiculoModule.getNeumaticosByIdVehiculo(parseInt(this.$route.params.id));
  }

  cancel() {
    if(this.checkNeumaticosVehiculo(this.vehiculo.neumaticos) && !this.vehiculo.id){
      this.currentAction= "checkNeumaticos";
      this.dialog = true;
    }
    else{
      this.$router.push(`/bases/${this.vehiculo.id_base}/vehiculos`);
    }
  }

  /*remove(item) {
    this.selectedProduct = item;
    this.dialog = true;
  }*/

  handleConfirm() {
    if (this.currentAction === 'checkNeumaticos') {
      this.dialog = true;
    } else if (this.currentAction === 'inspeccion') {
      this.$router.push(`/vehiculo/${this.vehiculo.id}/newinspeccion`);
    }
    this.dialogVisible = false;
  }

  onCancel() {
    this.dialog = false;
  }

  closeSnackbar() {
    appModule.closeNotice();
  }

  viewServicio(item) {
    console.log(item);
    /*servicioModule.setServicio(item);
    this.addServicio(item);*/
  }

  async created() {
    // this.getCustomers();
    appModule.setModal(false);
    //console.log(appModule.modal);
    //console.log(flotaModule.flota.id);
    const idBase= this.$route.params.id_base;
    const idFlota= flotaModule.flota.id;
    if(idFlota){
      vehiculoModule.getAllBasesByFlotaId(idFlota);
    }
    else{
      await baseModule.getBaseById(idBase);
      const idFlota= baseModule.base.id_flota;
      vehiculoModule.getAllBasesByFlotaId(idFlota);
    }
    neumaticoModule.getTiposNeumatico();
    this.getVehiculoById();
    this.vehiculo.id_base= parseInt(idBase);
  }

  mounted() {
    this.dialogTitle = this.$t("message.Neumáticos vehículo");
    this.dialogText = this.$t("message.Vehiculo sin todos los neumáticos necesarios");
    this.headers = [
      { text: this.$t("message.Nombre"), value: 'nombre' },
      { text: this.$t("message.Fecha"), value: 'fecha' },
      { text: '', value: 'actions', sortable: false }
    ];
    this.vehiculoMenus = [
      {
        title: this.$t("message.Nueva inspección"),
        link: 'inspeccion'
      },
      {
        title: this.$t("message.Nuevo servicio"),
        link: 'servicio'
      }
    ];
    if (this.$route.params.id) {
      this.title = this.$t("message.Editar Vehículo");
    } else{
      //console.log(this.$route.params.id_base);
      this.title = this.$t("message.Nuevo vehículo");
      //this.vehiculo.id_base= parseInt(this.$route.params.id_base);
    }
  }
}
