
import Vue from 'vue';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import { Component, Watch } from 'vue-property-decorator';
import { flotaModule } from '@/store/modules/flotas';
import { appModule } from '@/store/modules/app';

@Component({
  components: {
    ConfirmDialog
  }
})
export default class FlotaForm extends Vue {
 
  private orderDateMenu = false;
  private shippedDateMenu = false;
  private errors = [];
  private title = '';
  private productId = null;
  private categoryId = 0;
  private color = '';
  private selectedProduct: null;

  get flota() {
    return flotaModule.flota;
  }

  get categories() {
      const c= [{ text:this.$t("message.Mensual"), value:"mensual"}, { text:this.$t("message.Bimensual"), value:"bimensual"}, { text:this.$t("message.Trimestral"), value:"trimestral"}, { text:this.$t("message.Bisemanal"), value:"bisemanal"}, { text:this.$t("message.Semanal"), value:"semanal"}]
      return c;
  }

  get loading() {
    return appModule.loading;
  }

  get mode() {
    return appModule.mode;
  }

  get snackbar() {
    return appModule.snackbar;
  }

  get notice() {
    return appModule.notice;
  }

  save() {
    const flota = { ...this.flota };
    flotaModule.saveFlota(this.flota);
  }

  getFlotaById() {
    flotaModule.getFlotaById(this.$route.params.id);
  }

  cancel() {
    this.$router.push({ name: 'Flotas' });
  }

  /*remove(item) {
    this.selectedProduct = item;
    this.dialog = true;
  }

  onConfirm() {
    flotaModule.deleteProduct(this.selectedProduct);
    this.selectedProduct = null;
    this.dialog = false;
  }

  onCancel() {
    this.selectedProduct = null;
    this.dialog = false;
  }*/

  closeSnackbar() {
    appModule.closeNotice();
  }

  created() {
    // this.getCustomers();
    this.getFlotaById();
  }

  mounted() {
    if (this.$route.params.id) {
      this.title = this.$t("message.Editar Flota");
      this.$nextTick(() => {
        // this.shippedDate = this.order.shippedDate;
        // this.orderDate = this.order.orderDate;
      });
    } else this.title = this.$t("message.Nueva flota");
  }
}
