
import Vue from "vue";
import { Entity } from "@/types";
import { Component, Prop } from "vue-property-decorator";
import VehicleTiresData from "@/components/vehicleTiresData/index.vue";
import { neumaticoModule } from '@/store/modules/neumaticos';

@Component({
  components: {
    VehicleTiresData
  }
})
export default class TableReporteFlota extends Vue {
  @Prop() readonly data!: any;
  private itemsPerPage= 1;
  private currentPage= 1;
  private addNeumaticoModal= false;
  private modalTitle= "";
  private neumatico= {};
  private tireInfo= {};

  get paginatedItems() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      console.log(startIndex, endIndex);
      return this.data.bases.slice(startIndex, endIndex);
  }

  get pages() {
    return Math.ceil(this.data.bases.length / this.itemsPerPage);
  }

  addNeumatico(tireInfo) {
    console.log(tireInfo);
    this.modalTitle= this.$t("message.Neumático");
    this.tireInfo= tireInfo;
    //console.log(this.neumatico);
    this.addNeumaticoModal = true;
  }

  saveNeumatico() {
    this.addNeumaticoModal = false;
  }

  cancelAddNeumatico() {
    this.addNeumaticoModal = false;
  }

  changePage(page) {
    this.currentPage = page;
  }

  created() {
    console.log(this.data);
  }
}

