
import InfoBox from "@/components/dashboard/InfoBox.vue";
import MonthlySales from "@/components/dashboard/MonthlySales";
import OrderForecast from "@/components/dashboard/OrderForecast";
import BarChart from "@/components/dashboard/Bar";
import BrowserUsage from "@/components/dashboard/BrowserUsage";
import ProductAnalysis from "@/components/dashboard/ProductAnalysis";
import { Component, Prop } from "vue-property-decorator";
import Vue from "vue";

@Component({
  name: "Dashboard",
  components: {
    InfoBox,
    MonthlySales,
    OrderForecast,
    BarChart,
    BrowserUsage,
    ProductAnalysis
  }
})
export default class Dashboard extends Vue {}
