
import Vue from 'vue';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import { Component, Watch } from 'vue-property-decorator';
import { baseModule } from '@/store/modules/bases';
import { appModule } from '@/store/modules/app';
import axios from "axios";

@Component({
  components: {
    ConfirmDialog
  }
})
export default class BaseForm extends Vue {
 
  private orderDateMenu = false;
  private shippedDateMenu = false;
  private errors = [];
  private title = '';
  private productId = null;
  private categoryId = 0;
  private color = '';
  private selectedProduct: null;
  private data = null;

  get base() {
    return baseModule.base;
  }

  get flotas() {
    return baseModule.flotas;
  }

  get categories() {
      const c= [{ text:'Browseblab', value:"202"}, { text:'Edgeclub', value:"203"}, { text:'Nlounge', value:"208"}]
      return c;
  }

  get loading() {
    return appModule.loading;
  }

  get mode() {
    return appModule.mode;
  }

  get snackbar() {
    return appModule.snackbar;
  }

  get notice() {
    return appModule.notice;
  }

  save() {
    const base = { ...this.base };
    baseModule.saveBase(this.base);
  }

  getBaseById() {
    baseModule.getBaseById(this.$route.params.id);
  }

  get allFlotas() {
    return baseModule.flotas;
  }

  cancel() {
    const id_flota= this.base.id_flota;
    if(id_flota){
      this.$router.push(`/flota/${id_flota}/bases`);
    }
    else{
      this.$router.push(`/flotas`);
    }
  }

  closeSnackbar() {
    appModule.closeNotice();
  }

  created() {
    // this.getCustomers();
    baseModule.getAllFlotasSelect();
    this.getBaseById();
  }

  mounted() {
    
    if (this.$route.params.id) {
      this.title = this.$t("message.Editar Base");
      this.$nextTick(() => {
        // this.shippedDate = this.order.shippedDate;
        // this.orderDate = this.order.orderDate;
      });
    } else{
      this.title = this.$t("message.Nueva base");
    }
  }
}
