
import { Component, Prop } from "vue-property-decorator";
import Vue from "vue";

@Component({
  name: "InfoBox"
})
export default class InfoBox extends Vue {
  monthlyStats = [
    {
      bgColor: "cyan darken-3",
      iconClass: "amber white--text",
      icon: "mdi-wallet-membership",
      title: "New Order",
      data: "120",
      action: {
        label: "more",
        link: ""
      }
    },
    {
      bgColor: "deep-orange  lighten-3",
      iconClass: "cyan white--text",
      icon: "mdi-wallet-giftcard",
      title: "User Registrationsr",
      data: "780",
      action: {
        label: "more",
        link: ""
      }
    },
    {
      bgColor: "blue-grey lighten-4",
      iconClass: "orange darken-5 white--text",
      icon: "mdi-wallet-travel",
      title: "Unique Visitors",
      data: "78",
      action: {
        label: "more",
        link: ""
      }
    },
    {
      bgColor: "pink  darken-1",
      iconClass: "blue lighten-3 white--text",
      icon: "mdi-wall",
      title: "Bounce Rate",
      data: "53%",
      action: {
        label: "more",
        link: ""
      }
    }
  ];
}
