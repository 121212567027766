
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import TireBox from "./tireBox/index.vue";
import { Vehiculo } from "@/types";
import { Tire } from "@/types";

@Component({
  components: {
    TireBox
  }
})
export default class VehicleTiresData extends Vue {
  @Prop() vehicle!: Vehiculo;
  @Prop() inspeccion!: boolean;
  @Prop() neumaticosInspeccion!: [];
  @Prop() neumaticos!: Tire[];
  @Prop() informe!: boolean;

  get vehicleType(): number {
    return this.vehicle.tipo_vehiculo;
  }

  get vehicleTires(): any[] {
    //console.log(this.inspeccion);
    //console.log(this.neumaticos);
    //let neumaticos= this.neumaticos;
    //let neumaticos= vehiculoModule.vehiculo.neumaticos;
    let neumaticos= this.vehicle.neumaticos;
    console.log(neumaticos);
    const inspeccion= this.inspeccion;
    if(inspeccion){
      neumaticos= this.neumaticosInspeccion;
    }
    if(!neumaticos){
      neumaticos= [];
    }
    return this.baseTiresByVehicleType.map(tireData => {
      const tireFound = neumaticos.find(
        value => value.posicion == tireData.posicion
      );
      return { ...tireData, ...tireFound };
    });
  }

  findTiresOnAxix(tireAxis: string): any[] {
    return this.vehicleTires.filter(tire => {
      return tire.posicion.includes(tireAxis);
    });
  }

  get vehicleTiresGroupedByAxix(): any[] {
    switch (this.vehicleType) {
      case 1:
        return [
          {
            number: 1,
            type: "basic",
            tires: this.findTiresOnAxix("1"),
            areTiredAngled: true
          },
          {
            number: 2,
            type: "reinforced-small",
            tires: this.findTiresOnAxix("2")
          },
          {
            number: 3,
            type: "invisible",
            isSpareTire: true,
            tires: this.findTiresOnAxix("3")
          },
          {
            number: 4,
            type: "invisible",
            isSpareTire: true,
            tires: this.findTiresOnAxix("4")
          }
        ];

      case 2:
        return [
          {
            number: 1,
            type: "basic",
            tires: this.findTiresOnAxix("1")
          },
          {
            number: 2,
            type: "basic",
            tires: this.findTiresOnAxix("2")
          },
          {
            number: 3,
            type: "basic",
            tires: this.findTiresOnAxix("3")
          },
          {
            number: 4,
            type: "invisible",
            isSpareTire: true,
            tires: this.findTiresOnAxix("4")
          },
          {
            number: 5,
            type: "invisible",
            isSpareTire: true,
            tires: this.findTiresOnAxix("5")
          }
        ];

      case 3:
        return [
          {
            number: 1,
            type: "basic",
            tires: this.findTiresOnAxix("1")
          },
          {
            number: 2,
            type: "basic",
            tires: this.findTiresOnAxix("2")
          },
          {
            number: 3,
            type: "invisible",
            isSpareTire: true,
            tires: this.findTiresOnAxix("3")
          },
          {
            number: 4,
            type: "invisible",
            isSpareTire: true,
            tires: this.findTiresOnAxix("4")
          }
        ];

      case 4:
        return [
          {
            number: 1,
            type: "reinforced-small",
            tires: this.findTiresOnAxix("1")
          },
          {
            number: 2,
            type: "reinforced-small",
            tires: this.findTiresOnAxix("2")
          },
          {
            number: 3,
            type: "invisible",
            isSpareTire: true,
            tires: this.findTiresOnAxix("3")
          },
          {
            number: 4,
            type: "invisible",
            isSpareTire: true,
            tires: this.findTiresOnAxix("4")
          }
        ];

        case 5:
          return [
            {
              number: 1,
              type: "reinforced-small",
              tires: this.findTiresOnAxix("1")
            },
            {
              number: 2,
              type: "reinforced-small",
              tires: this.findTiresOnAxix("2")
            },
            {
              number: 3,
              type: "reinforced-small",
              tires: this.findTiresOnAxix("3")
            },
            {
              number: 4,
              type: "invisible",
              isSpareTire: true,
              tires: this.findTiresOnAxix("4")
            },
            {
              number: 5,
              type: "invisible",
              isSpareTire: true,
              tires: this.findTiresOnAxix("5")
            }
          ];
        case 6:
          return [
            {
              number: 1,
              type: "basic",
              tires: this.findTiresOnAxix("1")
            },
            {
              number: 2,
              type: "reinforced-small",
              tires: this.findTiresOnAxix("2")
            },
            {
              number: 3,
              type: "reinforced-small",
              tires: this.findTiresOnAxix("3")
            },
            {
              number: 4,
              type: "invisible",
              isSpareTire: true,
              tires: this.findTiresOnAxix("4")
            },
            {
              number: 5,
              type: "invisible",
              isSpareTire: true,
              tires: this.findTiresOnAxix("5")
            }
          ];

        case 7:
          return [
            {
              number: 1,
              type: "basic",
              tires: this.findTiresOnAxix("1")
            },
            {
              number: 2,
              type: "reinforced-small",
              tires: this.findTiresOnAxix("2")
            },
            {
              number: 3,
              type: "basic",
              tires: this.findTiresOnAxix("3")
            },
            {
              number: 4,
              type: "invisible",
              isSpareTire: true,
              tires: this.findTiresOnAxix("4")
            },
            {
              number: 5,
              type: "invisible",
              isSpareTire: true,
              tires: this.findTiresOnAxix("5")
            }
          ];

      default:
        return [];
    }
  }

  get baseTiresByVehicleType() {
    switch (this.vehicleType) {
      case 1:
        return [
          { posicion: "1I" },
          { posicion: "1D" },
          { posicion: "2IE" },
          { posicion: "2II" },
          { posicion: "2DI" },
          { posicion: "2DE" },
          { posicion: "3" },
          { posicion: "4" }
        ];

      case 2:
        return [
          { posicion: "1I" },
          { posicion: "1D" },
          { posicion: "2I" },
          { posicion: "2D" },
          { posicion: "3I" },
          { posicion: "3D" },
          { posicion: "4" },
          { posicion: "5" }
        ];

      case 3:
        return [
          { posicion: "1I" },
          { posicion: "1D" },
          { posicion: "2I" },
          { posicion: "2D" },
          { posicion: "3" },
          { posicion: "4" }
        ];
      case 4:
        return [
          { posicion: "1IE" },
          { posicion: "1II" },
          { posicion: "1DI" },
          { posicion: "1DE" },
          { posicion: "2IE" },
          { posicion: "2II" },
          { posicion: "2DI" },
          { posicion: "2DE" },
          { posicion: "3" },
          { posicion: "4" }
        ];
      case 5:
        return [
          { posicion: "1IE" },
          { posicion: "1II" },
          { posicion: "1DI" },
          { posicion: "1DE" },
          { posicion: "2IE" },
          { posicion: "2II" },
          { posicion: "2DI" },
          { posicion: "2DE" },
          { posicion: "3IE" },
          { posicion: "3II" },
          { posicion: "3DI" },
          { posicion: "3DE" },
          { posicion: "4" },
          { posicion: "5" }
        ];
      case 6:
        return [
          { posicion: "1I" },
          { posicion: "1D" },
          { posicion: "2IE" },
          { posicion: "2II" },
          { posicion: "2DI" },
          { posicion: "2DE" },
          { posicion: "3IE" },
          { posicion: "3II" },
          { posicion: "3DI" },
          { posicion: "3DE" },
          { posicion: "4" },
          { posicion: "5" }
        ];
      case 7:
        return [
          { posicion: "1I" },
          { posicion: "1D" },
          { posicion: "2IE" },
          { posicion: "2II" },
          { posicion: "2DI" },
          { posicion: "2DE" },
          { posicion: "3I" },
          { posicion: "3D" },
          { posicion: "4" },
          { posicion: "5" }
        ];

      default:
        return [];
    }
  }

  sendEventAddTire(tirePosition: string) {
    this.$emit("addTireEvent", tirePosition);
  }
}
