
import Vue from "vue";
import { Entity } from "@/types";
import { Component, Prop } from "vue-property-decorator";
import { userModule } from '@/store/modules/user';

@Component
export default class Table extends Vue {
  @Prop() readonly headers: TableHeader[];
  @Prop() readonly items: Entity[];
  @Prop() readonly pagination: Pagination;
  @Prop() readonly isRemovable: boolean;
  @Prop() readonly isEditable: boolean;
  @Prop() readonly isView: boolean;

  search = "";

  get user() {
    return userModule.user;
  }

  isNotEmpty() {
    return this.items && this.items.length > 0;
  }

  hasHeader(header: TODO) {
    return header?.value ? true : false;
  }
}
