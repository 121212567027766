export const es= {
  "message": {
    "Flotas": "Flotas",
    "Borrar flota": "Borrar flota",
    "¿Quieres borrar esta flota?": "¿Quieres borrar esta flota?",
    "Nombre": "Nombre",
    "Vehículos": "Vehículos",
    "Confirmar": "Confirmar",
    "Cancelar": "Cancelar",
    "Añadir servicio vehículo": "Añadir servicio vehículo",
    "Tipo de servicio": "Tipo de servicio",
    "Tipo de servicio es requerido": "Tipo de servicio es requerido",
    "Km recorrido": "Km recorrido",
    "Km recorrido es requerido": "Km recorrido es requerido",
    "Comentario": "Comentario",
    "Alineacion dirección": "Alineacion dirección",
    "Alineacion doble dirección": "Alineacion doble dirección",
    "Comprobacion alineación dirección": "Comprobacion alineación dirección",
    "Salidas coche taller": "Salidas coche taller",
    "Buscar": "Buscar",
    "Nombre is required": "Nombre is required",
    "Flota": "Flota",
    "Editar Base": "Editar Base",
    "Nueva base": "Nueva base",
    "Bases": "Bases",
    "Borrar base": "Borrar base",
    "¿Quieres borrar esta base?": "¿Quieres borrar esta base?",
    "Criterio de inspección": "Criterio de inspección",
    "Mensual": "Mensual",
    "Bimensual": "Bimensual",
    "Trimestral": "Trimestral",
    "Editar Flota": "Editar Flota",
    "Nueva flota": "Nueva flota",
    "Flota es requerido": "Flota es requerido.",
    "Fecha inicio": "Fecha inicio",
    "Fecha fin": "Fecha fin",
    "Exportar a Excel": "Exportar a Excel",
    "Generar informe": "Generar informe",
    "Calendario de inspecciones": "Calendario de inspecciones",
    "Informes de trabajo": "Informes de trabajo",
    "Fleet service request summary": "Fleet service request summary",
    "Informe neumático": "Informe neumático",
    "Informe neumático por vehículo": "Informe neumático por vehículo",
    "Base": "Base",
    "Matrícula": "Matrícula",
    "Taller/Usuario": "Taller/Usuario",
    "Servicio": "Servicio",
    "Neumático": "Neumático",
    "Posición": "Posición",
    "Fecha": "Fecha",
    "N serie": "N serie",
    "Tipo neumático": "Tipo neumático",
    "Rendimiento km": "Rendimiento km",
    "Siniestros": "Siniestros",
    "calendario_inspeccion_vehiculo": "calendario_inspeccion_vehiculo",
    "extraccion_informes_trabajo": "extraccion_informes_trabajo",
    "reporte_flota": "reporte_flota",
    "informe_neumatico": "informe_neumatico",
    "informe_neumatico_vehiculo": "informe_neumatico_vehiculo",
    "Matrícula is required": "Matrícula is required",
    "Km vehículo": "Km vehículo",
    "Km is required": "Km is required",
    "Km vehículo debe ser mayor que los km actuales": "Km vehículo debe ser mayor que los km actuales",
    "Número de serie": "Número de serie",
    "Prof 1(mm)": "Prof 1(mm)",
    "Prof 1(mm) is required": "Prof 1(mm) is required",
    "Prof 2(mm)": "Prof 2(mm)",
    "Prof 2(mm) is required": "Prof 2(mm) is required",
    "Prof 3(mm)": "Prof 3(mm)",
    "Prof 3(mm) is required": "Prof 3(mm) is required",
    "Medido (Bar)": "Medido (Bar)",
    "Medido (Bar) is required": "Medido (Bar) is required",
    "Recomendado (Bar)": "Recomendado (Bar)",
    "Recomendado (Bar) is required": "Recomendado (Bar) is required",
    "Corregido (Bar)": "Corregido (Bar)",
    "Corregido (Bar) is required": "Corregido (Bar) is required",
    "Añadir inspección neumático": "Añadir inspección neumático",
    "Neumático vehículo": "Neumático vehículo",
    "No existe el neumático en el vehículo": "No existe el neumático en el vehículo",
    "Inspección vehículo": "Inspección vehículo",
    "Es requerido realizar la inspección a todos los neumáticos": "Es requerido realizar la inspección a todos los neumáticos.",
    "Matrícula es requerido": "Matrícula es requerido",
    "Base es requerido": "Base es requerido",
    "Clase vehículo": "Clase vehículo",
    "Clase vehículo es requerido": "Clase vehículo es requerido",
    "Tipos vehículo": "Tipos vehículo",
    "Tipo de vehículo es requerido": "Tipo de vehículo es requerido",
    "Km vehículo es requerido": "Km vehículo es requerido",
    "Observaciones vehículo": "Observaciones vehículo",
    "Histórico del vehículo": "Histórico del vehículo",
    "Posición del eje": "Posición del eje",
    "Tipo de servicio is required": "Tipo de servicio is required",
    "Km vehículo neumático is required": "Km vehículo neumático is required",
    "Motivo cambio": "Motivo cambio",
    "Motivo cambio is required": "Motivo cambio is required",
    "Posición del eje es requerido.": "Posición del eje es requerido.",
    "Cabeza tractora": "Cabeza tractora",
    "Remolque": "Remolque",
    "Remolque sin km": "Remolque sin km",
    "Rígido": "Rígido",
    "Dumper": "Dumper",
    "Pala cargadora": "Pala cargadora",
    "Tractor": "Tractor",
    "Lagarto": "Lagarto",
    "Autobús": "Autobús",
    "Fin de vida por desgaste": "Fin de vida por desgaste",
    "Siniestro": "Siniestro",
    "Nuevo neumático": "Nuevo neumático",
    "Añadir servicio neumático": "Añadir servicio neumático",
    "Desmontar neumático": "Desmontar neumático",
    "Permutación": "Permutación",
    "Equilibrado de neumático": "Equilibrado de neumático",
    "Equilibrado polvos-sacos": "Equilibrado polvos-sacos",
    "Reesculturado": "Reesculturado",
    "Reparación pinchazo": "Reparación pinchazo",
    "Rotación en llanta": "Rotación en llanta",
    "Neumáticos vehículo": "Neumáticos vehículo",
    "Vehiculo sin todos los neumáticos necesarios": "Vehiculo sin todos los neumáticos necesarios",
    "Nueva inspección": "Nueva inspección",
    "Nuevo servicio": "Nuevo servicio",
    "Editar Vehículo": "Editar Vehículo",
    "Nuevo vehículo": "Nuevo vehículo",
    "Borrar vehículo": "Borrar vehículo",
    "¿Quieres borrar este vehículo?": "¿Quieres borrar este vehículo?",
    "Montar neumático": "Montar neumático",
    "Vehículo ha sido actualizado": "Vehículo ha sido actualizado.",
    "Nuevo vehículo añadido": "Nuevo vehículo añadido.",
    "Nuevo servicio ha sido añadido": "Nuevo servicio ha sido añadido.",
    "Calendario inspecciones": "Calendario inspecciones",
    "Informes": "Informes",
    "Usuarios": "Usuarios",
    "Importar neumáticos": "Importar neumáticos",
    "Fleet service request": "Fleet service request",
    "Informe inspecciones": "Informe inspecciones",
    "Enero": "Enero",
    "Febrero": "Febrero",
    "Marzo": "Marzo",
    "Abril": "Abril",
    "Mayo": "Mayo",
    "Junio": "Junio",
    "Julio": "Julio",
    "Agosto": "Agosto",
    "Septiembre": "Septiembre",
    "Octubre": "Octubre",
    "Noviembre": "Noviembre",
    "Diciembre": "Diciembre",
    "Año": "Año",
    "Mes": "Mes",
    "Milímetros": "Milímetros",
    "informe_inspecciones": "informe_inspecciones",
    "Bisemanal": "Bisemanal",
    "Matrícula existente": "Matrícula existente.",
    "Semanal": "Semanal",
    "Turismo": "Turismo",
    "aviso_inspeccion": "Según el criterio de inspección no debe realizarse aún una nueva inspección. La última inspección fue el: ",
    "¿Deséa realizarla?": "¿Deséa realizarla?",
    "Fecha última inspección": "Fecha última inspección",
    "Vehículos revisados": "Vehículos revisados",
    "(Rayado)": "(Rayado)",
    "Km al montaje": "Km al montaje"
  }
};

export const fr= {
  "message": {
    "Confirmar": "Confirmer ",
    "Cancelar": "Annuler",
    "Añadir servicio vehículo": "Ajouter service véhicule",
    "Tipo de servicio": "Type de service ",
    "Tipo de servicio es requerido": "Type de service requis",
    "Km recorrido": "Kms parcourus ",
    "Km recorrido es requerido": "Nombre de kms parcourus requis",
    "Comentario": "Commentaire ",
    "Alineacion dirección": "Parallélisme direction",
    "Alineacion doble dirección": "Parallélisme bidirectionnel",
    "Comprobacion alineación dirección": "Vérification parallélisme direction",
    "Salidas coche taller": "Sortie fourgon atelier",
    "Buscar": "Rechercher",
    "Nombre": "Nom",
    "Nombre is required": "Nom requis", 
    "Flota": "Flotte",
    "Editar Base": "Editer base de données",
    "Nueva base": "Nouvelle base de données",
    "Bases": "Base de données",
    "Borrar base": "Supprimer la base de données",
    "¿Quieres borrar esta base?": "¿Tu veux effacer cette base de données?",
    "Vehículos": "Véhicules",
    "Criterio de inspección": "Critères d’inspection",
    "Mensual": "Mensuel",
    "Bimensual": "Bimensuel",
    "Trimestral": "Trimestriel",
    "Editar Flota": "Editer flotte",
    "Nueva flota": "Nouvelle flotte",
    "Flotas": "Flottes",
    "Borrar flota": "Effacer flottes",
    "¿Quieres borrar esta flota?": "¿Tu veux effacer cette flotte?",
    "Flota es requerido": "Flotte requis",
    "Fecha inicio": "Date de début",
    "Fecha fin": "Date de fin",
    "Exportar a Excel": "Exporter au format Excel",
    "Generar informe": "Produire un rapport",
    "Calendario de inspecciones": "Calendrier des contrôles",
    "Informes de trabajo": "Rapports d’activité",
    "Fleet service request summary": "Résumé des demandes de services de la flotte",
    "Informe neumático": "Rapport sur les pneumatiques",
    "Informe neumático por vehículo": "Rapport sur les pneumatique par véhicule",
    "Base": "Base",
    "Matrícula": "Immatriculation ",
    "Taller/Usuario": "Atelier/utilisateur",
    "Servicio": "Service",
    "Neumático": "Pneumatique",
    "Posición": "Position",
    "Fecha": "Date",
    "N serie": "Nº serie",
    "Tipo neumático": "Type de pneumatique",
    "Rendimiento km": "Performance kilométrique",
    "Siniestros": "Sinistres",
    "calendario_inspeccion_vehiculo": "calendrier_inspection_véhicule",
    "extraccion_informes_trabajo": "extraction_rapport_travail",
    "reporte_flota": "rapport_flotte",
    "informe_neumatico": "rapport_pneumatique",
    "informe_neumatico_vehiculo": "rapport_pneumatique_véhicule",
    "Matrícula is required": "Immatriculation demandée",
    "Km vehículo": "Kms véhicule",
    "Km is required": "Kms requis",
    "Km vehículo debe ser mayor que los km actuales": "Kms véhicule doivent être supérieurs à la valeur actuelle des kms",
    "Número de serie": "Numéro de série",
    "Prof 1(mm)": "Prof 1 (mm)",
    "Prof 1(mm) is required": "Prof 1 (mm) requis",
    "Prof 2(mm)": "Prof 2(mm)",
    "Prof 2(mm) is required": "Prof 2(mm) requis",
    "Prof 3(mm)": "Prof 3 (mm)",
    "Prof 3(mm) is required": "Prof 3 (mm)",
    "Medido (Bar)": "Mesure (Bar)",
    "Medido (Bar) is required": "Mesure (Bar) requise",
    "Recomendado (Bar)": "Recommandé (Bar)",
    "Recomendado (Bar) is required": "Recommandé (Bar) est requis",
    "Corregido (Bar)": "Corrigé (Bar)",
    "Corregido (Bar) is required": "Corrigé (Bar) est requis",
    "Añadir inspección neumático": "Ajouter inspection pneumatique",
    "Neumático vehículo": "Pneumatique véhicule",
    "No existe el neumático en el vehículo": "Le pneu n’existe pas sur le véhicule",
    "Inspección vehículo": "Inspection véhicule",
    "Es requerido realizar la inspección a todos los neumáticos": "L’inspection de tous les pneus est requise",
    "Matrícula es requerido": "Immatriculation requise",
    "Base es requerido": "Base de données nécessaire",
    "Clase vehículo": "Catégorie de véhicule",
    "Clase vehículo es requerido": "Catégorie de véhicule requise",
    "Tipos vehículo": "Types de véhicules",
    "Tipo de vehículo es requerido": "Types de véhicules requis",
    "Km vehículo es requerido": "Kilométrage véhicule requis",
    "Observaciones vehículo": "Observations véhicule",
    "Histórico del vehículo": "Historique du véhicule",
    "Posición del eje": "Position de l’essieu",
    "Tipo de servicio is required": "Type de service requis",
    "Km vehículo neumático is required": "Kilométrage pneu véhicule requis",
    "Motivo cambio": "Motif de la modification",
    "Motivo cambio is required": "Motif de modification est requis",
    "Posición del eje es requerido": "Position de l’essieu requis",
    "Cabeza tractora": "Camion tracteur",
    "Remolque": "Remorque",
    "Remolque sin km": "Remorque sans km",
    "Rígido": "Rigide",
    "Dumper": "Dumper",
    "Pala cargadora": "Chargeuse",
    "Tractor": "Tracteur",
    "Lagarto": "Lézard",
    "Autobús": "Autobus",
    "Fin de vida por desgaste": "Fin de vie due à l´usure",
    "Siniestro": "Sinistre",
    "Nuevo neumático": "Nouveau pneumatique",
    "Añadir servicio neumático": "Ajouter un service pneumatique",
    "Desmontar neumático": "Démontage de pneumatique",
    "Permutación": "Permutation",
    "Equilibrado de neumático": "Equilibrage de penumatique",
    "Equilibrado polvos-sacos": "Equilibrage du sac à poudre ",
    "Reesculturado": "Recreusage de pneumatique",
    "Reparación pinchazo": "Réparation crevaison",
    "Rotación en llanta": "Rotation sur jante",
    "Neumáticos vehículo": "Pneumatiques véhicule",
    "Vehiculo sin todos los neumáticos necesarios": "Véhicule dépourvus des pneumatiques nécessaires",
    "Nueva inspección": "Nouvelle inspection",
    "Nuevo servicio": "Nouveau service",
    "Editar Vehículo": "Modifier véhicule",
    "Nuevo vehículo": "Nouveau véhicule",
    "Borrar vehículo": "Supprimer un véhicule",
    "¿Quieres borrar este vehículo?": "¿Tu veux effacer ce véhicule?",
    "Montar neumático": "Montage pneumatique",
    "Vehículo ha sido actualizado": "Véhicule a été  mis à niveau",
    "Nuevo vehículo añadido": "Ajout d’un nouveau véhicule",
    "Nuevo servicio ha sido añadido": "Ajout d’un nouveau service",
    "Calendario inspecciones": "Calendrier des inspections",
    "Informes": "Rapports d’activité",
    "Usuarios": "Utilisateurs",
    "Importar neumáticos": "Importation de pneus",
    "Fleet service request": "Résumé des demandes de services de la flotte",
    "Informe inspecciones": "Rapport des inspections",
    "Enero": "Janvier",
    "Febrero": "Février",
    "Marzo": "Mars",
    "Abril": "Avril",
    "Mayo": "Mai",
    "Junio": "Juin",
    "Julio": "Juillet",
    "Agosto": "Août",
    "Septiembre": "Septembre",
    "Octubre": "Octobre",
    "Noviembre": "Novembre",
    "Diciembre": "Décembre",
    "Año": "Année",
    "Mes": "Mois",
    "Milímetros": "Millimètres",
    "informe_inspecciones": "rapport_des_inspections",
    "Bisemanal": "Bihebdomadaire",
    "Matrícula existente": "Immatriculation existante.",
    "Semanal": "Hebdomadaire",
    "Turismo": "Véhicule TC4",
    "aviso_inspeccion": "Selon les critères d'inspection, une nouvelle inspection ne doit pas encore être effectuée. La dernière inspection a eu lieu le : ",
    "¿Deséa realizarla?": "Souhaitez-vous effectuer une nouvelle inspection?",
    "Fecha última inspección": "Date du dernier contrôle",
    "Vehículos revisados": "Véhicules contrôlés",
    "(Rayado)": "(Doublé)",
    "Km al montaje": "Km jusqu'à l'assemblage"
  }
};