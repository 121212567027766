
import Table from '@/components/Table.vue';
import TableHeaderButtons from '@/components/TableHeaderButtons.vue'
import Vue from 'vue';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import { Component, Watch } from 'vue-property-decorator';
import { flotaModule } from '@/store/modules/flotas';
import { appModule } from '@/store/modules/app';
import { informeModule } from '@/store/modules/informes';
import * as XLSX from 'xlsx'
import jsPDF from 'jspdf';
import html2canvas from "html2canvas";
import domtoimage from "dom-to-image";
import TableReporteFlota from '@/components/TableReporteFlota.vue';
import { baseModule } from '@/store/modules/bases';

@Component({
  components: {
    Table,
    TableHeaderButtons,
    ConfirmDialog,
    TableReporteFlota
  }
})
export default class InformeForm extends Vue {
  private errors = [];
  //private title = '';
  private data: [];
  private dialog = false;
  private dialogTitle = "";
  private dialogText= "";
  private fechaInicioDateMenu = false;
  private fechaFinDateMenu = false;
  private filename: 'calendario_inspeccion.xlsx';
  private nombreFlota= '';
  private URL= 'https://fleet.recacor.es/';
  private informeJson= null;

  get allFlotas() {
    console.log(flotaModule.items);
    return flotaModule.items;
  }

  get allBases() {
    console.log(baseModule.items);
    return baseModule.items;
  }

  get informe() {
    return informeModule.informe;
  }

  get loading() {
    return appModule.loading;
  }

  get mode() {
    return appModule.mode;
  }

  get snackbar() {
    return appModule.snackbar;
  }

  get notice() {
    return appModule.notice;
  }

  get tiposInformes() {
    const tiposInformes= [{ text:this.$t("message.Calendario de inspecciones"), value:'calendario_inspeccion_vehiculo'}, { text:this.$t("message.Informes de trabajo"), value:'extraccion_informes_trabajo'}, { text:this.$t("message.Fleet service request summary"), value:'reporte_flota'}, { text:this.$t("message.Informe neumático"), value:'informe_neumatico'}, { text:this.$t("message.Informe neumático por vehículo"), value:'informe_neumatico_vehiculo'}, { text:this.$t("message.Informe inspecciones"), value:'informe_inspecciones'}];
    return tiposInformes;
  }

  get title() {
    const tipoInforme = this.$route.params.tipo_informe;
    const tipoInformeEncontrado = this.tiposInformes.find(i => i.value === tipoInforme);
    return tipoInformeEncontrado ? tipoInformeEncontrado.text : '';
  }

  get meses() {
    const meses= [{ text:this.$t("message.Enero"), value:'01'}, { text:this.$t("message.Febrero"), value:'02'}, { text:this.$t("message.Marzo"), value:'03'}, { text:this.$t("message.Abril"), value:'04'}, { text:this.$t("message.Mayo"), value:'05'}, { text:this.$t("message.Junio"), value:'06'}, { text:this.$t("message.Julio"), value:'07'}, { text:this.$t("message.Agosto"), value:'08'}, { text:this.$t("message.Septiembre"), value:'09'}, { text:this.$t("message.Octubre"), value:'10'}, { text:this.$t("message.Noviembre"), value:'11'}, { text:this.$t("message.Diciembre"), value:'12'}];
    return meses;
  }

  get years() {
    const startYear= 2023;
    const endYear= new Date().getFullYear();
    const years = [];
    for (let year = startYear; year <= endYear; year++) {
      years.push(year);
    }
    return years;
  }

  convertToSheetCalendarioInspeccion(json) {
          // Crear una estructura de datos que contenga una fila por vehículo y una columna por mes
          const data = [];
          const pendientesData = [];
          console.log(this.informe)
          let pendientes= 0;
          let n_vehiculos= 0;
          //let fechaInicio= this.informe.fecha_inicio;
          //let fechaFin= this.informe.fecha_fin;

          const fecha_inicio = new Date(this.informe.fecha_inicio);
          const fecha_fin = new Date(this.informe.fecha_fin);

          const fechas = [];

          for (let fecha = fecha_inicio; fecha <= fecha_fin; fecha.setMonth(fecha.getMonth() + 1)) {
            const anio = fecha.getFullYear();
            const mes = fecha.getMonth() + 1;

            fechas.push(`${anio}-${mes.toString().padStart(2, '0')}`);
          }

          console.log(fechas);

          data.push([this.$t("message.Base"), this.$t("message.Matrícula"), ...fechas, this.$t("message.Fecha última inspección")]);
          pendientesData.push([this.$t("message.Base"), this.$t("message.Matrícula"), this.$t("message.Fecha última inspección")]);

          json.forEach(base => {
            n_vehiculos+= base.n_vehiculos;
            //data.push(['Base', base.nombre]);
            const meses = fechas;
            //console.log(meses)
            const matriculas = [];
            //console.log(base.inspecciones)
            meses.forEach(mes => {
              //console.log(base.inspecciones)
              if(base.inspecciones){
                const inspecciones = base.inspecciones[mes];
                if(inspecciones){
                  inspecciones.forEach(inspeccion => {
                    if (!matriculas.includes(inspeccion.matricula)) {
                      matriculas.push(inspeccion.matricula);
                    }
                  });
                }
              }
            });
            //data.push(['Matrícula', ...meses]);
            matriculas.forEach(matricula => {
              const fila = [base.nombre, matricula];
              //console.log(fila)
              let ultimaInspeccion= '';
              meses.forEach(mes => {
                const inspecciones = base.inspecciones[mes];
                //console.log("inspecciones", inspecciones)
                if(inspecciones){
                  const inspeccion = inspecciones.filter(i => i.matricula === matricula);
                  if (inspeccion) {
                    let inspeccionesString = '';
                    let inspeccionUrl= '';

                    inspeccion.forEach((inspeccion, index) => {
                        inspeccionesString= inspeccion.id_inspeccion;
                        inspeccionUrl= this.URL + 'vehiculo/' + `${inspeccion.id_vehiculo}` + '/inspeccion/' + `${inspeccion.id_inspeccion}`;
                        
                        if (index > 0) {
                            inspeccionesString += '-'+ (parseInt(index)+1);
                        }

                        ultimaInspeccion = inspeccion.fecha;
                    });

                    const style = {
                      font: {
                        color: { rgb: '0000FF' },
                        underline: true
                      }
                    };
                    fila.push({ f: `=HYPERLINK("${inspeccionUrl}", "${inspeccionesString}")`,
                                s: style
                    });
                  } else {
                    fila.push('');
                  }
                }
                else {
                  fila.push('');
                }
              });
              fila.push(ultimaInspeccion);
              data.push(fila);
            });
            if (base.pendientes) {
              pendientes+= base.pendientes.length;
              base.pendientes.forEach(pendiente => {
                const filaPendiente = [base.nombre, pendiente.matricula, pendiente.fecha];
                pendientesData.push(filaPendiente);
              });
            }
          });
          //console.log(Math.round((parseInt(n_vehiculos)/parseInt(pendientes)-1)*100));
          const revisados= parseInt(n_vehiculos)-parseInt(pendientes);
          const percentageReviewed= Math.round((parseInt(revisados)/parseInt(n_vehiculos))*100);
          this.dialogText= this.$t("message.Vehículos revisados")+`: ${percentageReviewed}%`;
          this.dialog = true;

          //console.log(data);
          //console.log(pendientesData);
          // Crear una hoja de Excel a partir de la estructura de datos
          const workbook = XLSX.utils.book_new();
          const ws = XLSX.utils.aoa_to_sheet(data);
          const pendientesWs = XLSX.utils.aoa_to_sheet(pendientesData);
          XLSX.utils.book_append_sheet(workbook, ws, "Calendario de Inspección");
          XLSX.utils.book_append_sheet(workbook, pendientesWs, "Pendientes de Inspección");

    // Generar el archivo de Excel y descargarlo
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    return excelBuffer;
  }

  convertToSheetExtraccionInformesTrabajo(json) {
          console.log(json);
          // Crear una estructura de datos que contenga una fila por vehículo y una columna por mes
          const data = [];
          const flota= json[0].nombre;
          data.push([this.$t("message.Taller/Usuario"), this.$t("message.Base"), this.$t("message.Matrícula"), this.$t("message.Servicio"), this.$t("message.Neumático"), this.$t("message.Posición"), this.$t("message.Fecha"), this.$t("message.N serie"), this.$t("message.Comentario")]);
          const bases = json[0].bases;
          const serviciosVehiculo= [];
          bases.forEach(base => {
            const nombreBase= base.nombre;
            base.vehiculos.forEach(vehiculo => {
              if(vehiculo.servicios.length){
                //data.push(['','Nombre servicio','Fecha de servicio','Comentario', 'Usuario']);
                vehiculo.servicios.forEach(servicio => {
                  data.push([servicio.usuario,base.nombre,vehiculo.matricula,this.$t("message."+servicio.nombre),'','',servicio.fecha,'',servicio.comentario]);
                  serviciosVehiculo.push(servicio);
                });
              }
              if(vehiculo.neumaticos.length){
                //data.push(['','','Neumáticos']);
                //data.push(['','Nombre','Posicion','ID servicio','Nombre servicio','Fecha de servicio','Comentario', 'Usuario']);
                vehiculo.neumaticos.forEach(neumatico => {
                  neumatico.servicios.forEach(servicio => {
                    data.push([servicio.usuario,base.nombre,vehiculo.matricula,this.$t("message."+servicio.nombre),neumatico.nombre,neumatico.posicion,servicio.fecha,neumatico.n_serie,servicio.comentario]);
                    serviciosVehiculo.push(servicio);
                  });
                });
              }
              console.log(serviciosVehiculo);
            });
          });
          console.log(data);
          // Crear una hoja de Excel a partir de la estructura de datos
          const workbook = XLSX.utils.book_new();
          const ws = XLSX.utils.aoa_to_sheet(data);
          XLSX.utils.book_append_sheet(workbook, ws, "");
    // Generar el archivo de Excel y descargarlo
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    return excelBuffer;
  }

  convertToSheetInformeInspecciones(json) {
          console.log(json);
          // Crear una estructura de datos que contenga una fila por vehículo y una columna por mes
          const data = [];
          const flota= json[0].nombre;
          data.push([this.$t("message.Base"), this.$t("message.Matrícula"), this.$t("message.Neumático"), this.$t("message.Posición"), this.$t("message.Milímetros"), this.$t("message.Fecha"), this.$t("message.Comentario")]);
          const bases = json[0].bases;
          const serviciosVehiculo= [];
          bases.forEach(base => {
            const nombreBase= base.nombre;
            data.push([nombreBase,"","","", "", "", ""]);
            base.vehiculos.forEach(vehiculo => {
              data.push(["",vehiculo.matricula,"","", "", "", ""]);
              //console.log(vehiculo.inspeccion);
              const inspecciones= vehiculo.inspecciones;
              if(inspecciones){
                inspecciones.forEach(inspeccion => {
                  if(inspeccion.neumaticos){
                    //data.push(['','','Neumáticos']);
                    //data.push(['','Nombre','Posicion','ID servicio','Nombre servicio','Fecha de servicio','Comentario', 'Usuario']);
                    inspeccion.neumaticos.forEach(neumatico => {
                      //console.log(neumatico);
                      const milimetros= neumatico.mm
                      let milimetrosString= parseInt(neumatico.mm);
                      if(neumatico.reesculturado){
                        milimetrosString+= this.$t("message.(Rayado)");
                      }
                      data.push(["",vehiculo.matricula,neumatico.nombre,neumatico.posicion, milimetrosString, inspeccion.fecha, inspeccion.comentario]);
                      //data.push([base.nombre,vehiculo.matricula,neumatico.nombre,neumatico.posicion, parseInt(neumatico.mm), inspeccion.fecha, inspeccion.comentario]);
                    });
                  }
                });
              }
            });
            data.push(["-","-","-","-", "-", "-", "-"]);
          });
          //console.log(data);
          // Crear una hoja de Excel a partir de la estructura de datos
          const workbook = XLSX.utils.book_new();
          const ws = XLSX.utils.aoa_to_sheet(data);
          XLSX.utils.book_append_sheet(workbook, ws, "");
    // Generar el archivo de Excel y descargarlo
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    return excelBuffer;
  }

  convertToSheetReporteFlota(json) {
          console.log(json);
          // Crear una estructura de datos que contenga una fila por vehículo y una columna por mes
          const data = [];
          data.push(['Flota', json[0].nombre]);
          const bases = json[0].bases;
          bases.forEach(base => {
            data.push(['Base', base.nombre]);
            base.vehiculos.forEach(vehiculo => {
              data.push(['Vehiculo', vehiculo.matricula]);
              if(vehiculo.servicios.length){
                data.push(['','Nombre servicio','Fecha de servicio','Comentario']);
                vehiculo.servicios.forEach(servicio => {
                  data.push(['',servicio.nombre,servicio.fecha,servicio.comentario]);
                });
              }
              if(vehiculo.neumaticos){
                //data.push(['','','Neumáticos']);
                data.push(['','Nombre','Posicion','ID servicio','Nombre servicio','Fecha de servicio','Comentario']);
                vehiculo.neumaticos.forEach(neumatico => {
                  neumatico.servicios.forEach(servicio => {
                    data.push(['',neumatico.nombre,neumatico.posicion,servicio.id,servicio.nombre,servicio.fecha,servicio.comentario]);
                  });
                });
              }
            });
          });
          console.log(data);
          // Crear una hoja de Excel a partir de la estructura de datos
          const workbook = XLSX.utils.book_new();
          const ws = XLSX.utils.aoa_to_sheet(data);
          XLSX.utils.book_append_sheet(workbook, ws, "");
    // Generar el archivo de Excel y descargarlo
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    return excelBuffer;
  }

  convertToSheetInformeNeumatico(json) {
          console.log(json);
          // Crear una estructura de datos que contenga una fila por vehículo y una columna por mes
          const data = [];
          const bases = json;
          console.log(bases);
          data.push([this.$t("message.Base"), this.$t("message.Tipo neumático"), this.$t("message.Rendimiento km"), this.$t("message.Siniestros")]);
          bases.forEach(base => {
            //data.push(['Base', base.nombre]);
            base.tipos_neumatico.forEach(tipoNeumatico => {
              data.push([base.nombre, tipoNeumatico.nombre, tipoNeumatico.rendimiento, tipoNeumatico.n_siniestros]);
            });
          });
          console.log(data);
          // Crear una hoja de Excel a partir de la estructura de datos
          const workbook = XLSX.utils.book_new();
          const ws = XLSX.utils.aoa_to_sheet(data);
          XLSX.utils.book_append_sheet(workbook, ws, "");
    // Generar el archivo de Excel y descargarlo
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    return excelBuffer;
  }

  convertToSheetInformeNeumaticoVehiculo(json) {
          //console.log(json);
          // Crear una estructura de datos que contenga una fila por vehículo y una columna por mes
          const data = [];
          const bases = json;
          //console.log(bases);
          data.push([this.$t("message.Base"), this.$t("message.Matrícula"), this.$t("message.Posición"), this.$t("message.Tipo neumático"), this.$t("message.Rendimiento km")]);
          bases.forEach(base => {
            base.vehiculos.sort((a, b) => {
                if (a.id === b.id) {
                  return a.posicion.localeCompare(b.posicion);
                }
                // Otherwise, sort by "id" in ascending order
                return a.id - b.id;
            });
            base.vehiculos.forEach(vehiculo => {
              data.push([base.nombre, vehiculo.matricula, vehiculo.posicion, vehiculo.tipo_neumatico, vehiculo.rendimiento]);
            });
          });
          //console.log(data);
          // Crear una hoja de Excel a partir de la estructura de datos
          const workbook = XLSX.utils.book_new();
          const ws = XLSX.utils.aoa_to_sheet(data);
          XLSX.utils.book_append_sheet(workbook, ws, "");
    // Generar el archivo de Excel y descargarlo
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    return excelBuffer;
  }

  async exportToExcel() {
    if((this.$refs.form as Vue & { validate: () => boolean }).validate()){
      console.log(this.$route.params.tipo_informe);
      const idFlota= this.informe.id_flota;
      const nombreFlota= this.allFlotas.find(flota => flota.id == idFlota).nombre;
      //console.log(this.allFlotas.find(flota => flota.id == idFlota).nombre);
      const tipoInforme= this.$route.params.tipo_informe;
      this.informe.tipo_informe= tipoInforme;
      if(tipoInforme=="informe_inspecciones"){
        const fechaActual = new Date();
        const year= this.informe.year;
        const mes= this.informe.mes;
        const fechaInicio= year+"-"+mes+"-01";
        //console.log(fechaInicio);
        let fechaFin= new Date(fechaInicio);
        fechaFin= new Date(fechaFin.setMonth(fechaFin.getMonth() + 1));
        const finYear = fechaFin.getFullYear();
        const finMes = (fechaFin.getMonth() + 1).toString().padStart(2, '0');
        const finDia = fechaFin.getDate().toString().padStart(2, '0');
        fechaFin = `${finYear}-${finMes}-${finDia}`;
        //console.log(fechaFin);
        this.informe.fecha_inicio= fechaInicio;
        this.informe.fecha_fin= fechaFin;
      }
      await informeModule.getInforme(this.informe);
      if(this.informe.json.length){
        //console.log(this.informe);
        let excelBuffer= new Uint8Array();
        let filename = '';
        if(tipoInforme== "calendario_inspeccion_vehiculo"){
          excelBuffer = this.convertToSheetCalendarioInspeccion(this.informe.json);
          filename = this.$t("message.calendario_inspeccion_vehiculo")+'_'+nombreFlota+'.xlsx';
        }
        else if(tipoInforme== "extraccion_informes_trabajo"){
          excelBuffer = this.convertToSheetExtraccionInformesTrabajo(this.informe.json);
          filename = this.$t("message.extraccion_informes_trabajo")+'_'+nombreFlota+'.xlsx';
        }
        else if(tipoInforme== "reporte_flota"){
          excelBuffer = this.convertToSheetReporteFlota(this.informe.json);
          filename = this.$t("message.reporte_flota")+'a_'+nombreFlota+'.pdf';
        }
        else if(tipoInforme== "informe_neumatico"){
          excelBuffer = this.convertToSheetInformeNeumatico(this.informe.json);
          filename = this.$t("message.informe_neumatico")+'_'+nombreFlota+'.xlsx';
        }
        else if(tipoInforme== "informe_neumatico_vehiculo"){
          excelBuffer = this.convertToSheetInformeNeumaticoVehiculo(this.informe.json);
          filename = this.$t("message.informe_neumatico_vehiculo")+'_'+nombreFlota+'.xlsx';
        }
        else if(tipoInforme== "informe_inspecciones"){
          excelBuffer = this.convertToSheetInformeInspecciones(this.informe.json);
          filename = this.$t("message.informe_inspecciones")+'_'+nombreFlota+'.xlsx';
        }
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, filename);
        } else {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
        }
      }
      else{
        appModule.sendErrorNotice(
          "No existen datos para generar informe."
        );
        appModule.closeNoticeWithDelay(1500);
      }
    }
  }

  async generateReporteFlota() {
    if((this.$refs.form as Vue & { validate: () => boolean }).validate()){
      console.log(this.$route.params.tipo_informe);
      const idFlota= this.informe.id_flota;
      //this.getBaseByIdFlota(idFlota);
      const nombreFlota= this.allFlotas.find(flota => flota.id == idFlota).nombre;
      const tipoInforme= this.$route.params.tipo_informe;
      this.informe.tipo_informe= tipoInforme;
      this.informe.json= {};
      await informeModule.getInforme(this.informe);
      console.log(this.informeJson);
      if(this.informe.json){
        this.informeJson= this.informe.json;
        console.log(this.informeJson);
        /*console.log(this.informe);
        const filename = tipoInforme+'_'+nombreFlota+'.pdf';
        const content = document.querySelector('.v-application--wrap');

        domtoimage
        .toPng(content)
        .then(function(dataUrl) {
          const img = new Image();
          img.src = dataUrl;
          const doc = new jsPDF({
            orientation: "portrait",
            // unit: "pt",
            format: 'a3'
          });
          doc.addImage(img, "JPEG", 20, 2000);
          doc.save(filename);
        })*/
      }
      else{
        appModule.sendErrorNotice(
          "No existen datos para generar informe."
        );
        appModule.closeNoticeWithDelay(1500);
      }
    }
  }

  getBaseByIdFlota(id) {
    baseModule.getBaseByIdFlota(id);
  }

  closeSnackbar() {
    appModule.closeNotice();
  }

  created() {
    appModule.setModal(false);
    flotaModule.getAllFlotas();
  }

  onConfirm() {
    this.dialog = false;
  }

  onCancel() {
    this.dialog = false;
  }

  mounted() {
    console.log(this.$route.params.tipo_informe);
    console.log(this.tiposInformes.find(i => i.value === this.$route.params.tipo_informe).text);
    //this.title = this.tiposInformes.find(i => i.value === this.$route.params.tipo_informe).text;
    const fecha = new Date();
    const anio = fecha.getFullYear();
    const mes = fecha.getMonth() + 1; // Los meses empiezan en 0, por eso se suma 1
    const dia = fecha.getDate();

    //console.log(`${anio-1}-${mes.toString().padStart(2, '0')}-${dia.toString().padStart(2, '0')}`);
    this.informe.fecha_inicio= `${anio-1}-${mes.toString().padStart(2, '0')}-${dia.toString().padStart(2, '0')}`;
    this.informe.fecha_fin= `${anio}-${mes.toString().padStart(2, '0')}-${dia.toString().padStart(2, '0')}`;

    this.dialogTitle = this.$t("message.Vehículos revisados");
  }
}
