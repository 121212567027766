
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { userModule } from "@/store/modules/user";

@Component
export default class Login extends Vue {
  private email = "";
  private pass = "";
  private error = false;
  private text = "Datos no válidos";

  public gotoDashboard() {
    this.$router.push("/flotas");
  }

  public async login() {
    this.error= await userModule.signIn({ usr: this.email, pwd: this.pass });
    //console.log(this.$router.currentRoute.query.redirect)
    const redirect= this.$router.currentRoute.query.redirect;
    if(redirect){
      this.$router.push(`${this.$router.currentRoute.query.redirect}`);
    }
    else{
      this.$router.push("/flotas");
    }
  }
}
